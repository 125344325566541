import React, { useState } from "react";
import axios from "axios";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import Logo from "../assets/img/logo-ct.png";

export default function CertificadoForm() {
  const [formData, setFormData] = useState({
    nomeComprador: "",
    enderecoComprador: "",
    cidadeEstadoComprador: "",
    paisComprador: "",
    nomeVendedor: "",
    enderecoVendedor: "",
    cidadeEstadoVendedor: "",
    paisVendedor: "",
    quantidade: "",
    preco: "",
  });

  const [message, setMessage] = useState("");
  const [messageBg, setMessageBg] = useState("");

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    axios
      .post("https://bcv.institutosudamericanodecarbono.group/api/send/datacert", data)
      .then((response) => {
        console.log(response)
        if (response.data.status === 1) {
          setMessage("Certificado Solicitado com sucesso!");
          setMessageBg("bg-green-500");
          setTimeout(() => {
            setMessage("");
            setMessageBg("");
          }, 5000);
        }
        if (response.data.status === 0) {
          setMessage("Erro ao solicitar certificado!");
          setMessageBg("bg-red-500");
          setTimeout(() => {
            setMessage("");
            setMessageBg("");
          }, 5000);
        }

        /*  const fileURL = `/${response.data.pathPdf}`;
        window.open(fileURL);
        var a = document.createElement("a");
        a.href = fileURL;
        a.target = "_blank";
        a.click(); */
      })
      .catch((error) => {
        setMessage("Erro ao solicitar certificado!");
        setMessageBg("bg-red-500");
        setTimeout(() => {
          setMessage("");
          setMessageBg("");
        }, 3000);
        alert(error);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundImage: Logo,
          backgroundColor: "rgb(8, 26, 28)",
          height: "max-content",
        }}
      >
        <Navbar />
        <div className="w-2/3 mx-auto py-20 text-white">
          <h1 className="text-3xl font-bold text-center my-6">
            Solicitação de Certificado CPR Verde
          </h1>

          <p className="mb-4">
            O certificado CPR Verde é um documento digital que atesta a
            conformidade de produtos de construção com as normas de desempenho
            europeias. Esse certificado é emitido por organismos notificados
            pela União Europeia e é obrigatório para a comercialização de
            produtos de construção na Europa.
          </p>
          {/* <p className="mb-4">
            Para preencher o formulário de solicitação do certificado CPR Verde,
            você deve fornecer informações detalhadas sobre o produto de
            construção, como a sua função, características técnicas e requisitos
            de desempenho. Além disso, é necessário apresentar documentos que
            comprovem a conformidade do produto com as normas europeias, como
            ensaios laboratoriais e certificados de qualidade.
          </p> */}
          <p>
            O processo de obtenção do certificado CPR Verde pode ser complexo,
            mas é essencial para a comercialização de produtos de construção. Portanto, é importante seguir todas as etapas do processo
            com cuidado e precisão.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="w-2/3 mx-auto py-20">
          <div className="mb-4">
            <label
              htmlFor="nomeComprador"
              className="block font-medium text-white"
            >
              Nome do Comprador
            </label>
            <input
              type="text"
              name="nomeComprador"
              onChange={handleChange}
              value={formData.nomeComprador}
              id="nomeComprador"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="enderecoComprador"
              className="block font-medium text-white"
            >
              Endereço do Comprador
            </label>
            <input
              type="text"
              name="enderecoComprador"
              onChange={handleChange}
              value={formData.enderecoComprador}
              id="enderecoComprador"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="cidadeEstadoComprador"
              className="block font-medium text-white"
            >
              Cidade/Estado do Comprador
            </label>
            <input
              type="text"
              name="cidadeEstadoComprador"
              onChange={handleChange}
              value={formData.cidadeEstadoComprador}
              id="cidadeEstadoComprador"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="paisComprador"
              className="block font-medium text-white"
            >
              País do Comprador
            </label>
            <input
              type="text"
              name="paisComprador"
              onChange={handleChange}
              value={formData.paisComprador}
              id="paisComprador"
              className="mt-1 block w-full rounded-md
border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="nomeVendedor"
              className="block font-medium text-white"
            >
              Nome do Vendedor
            </label>
            <input
              type="text"
              name="nomeVendedor"
              onChange={handleChange}
              value={formData.nomeVendedor}
              id="nomeVendedor"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="enderecoVendedor"
              className="block font-medium text-white"
            >
              Endereço do Vendedor
            </label>
            <input
              type="text"
              name="enderecoVendedor"
              onChange={handleChange}
              value={formData.enderecoVendedor}
              id="enderecoVendedor"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="cidadeEstadoVendedor"
              className="block font-medium text-white"
            >
              Cidade/Estado do Vendedor
            </label>
            <input
              type="text"
              name="cidadeEstadoVendedor"
              onChange={handleChange}
              value={formData.cidadeEstadoVendedor}
              id="cidadeEstadoVendedor"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="paisVendedor"
              className="block font-medium text-white"
            >
              País do Vendedor
            </label>
            <input
              type="text"
              name="paisVendedor"
              onChange={handleChange}
              value={formData.paisVendedor}
              id="paisVendedor"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="quantidade"
              className="block font-medium text-white"
            >
              Quantidade
            </label>
            <input
              type="text"
              name="quantidade"
              onChange={handleChange}
              value={formData.quantidade}
              id="quantidade"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="preco" className="block font-medium text-white">
              Preço
            </label>
            <input
              type="text"
              name="preco"
              onChange={handleChange}
              value={formData.preco}
              id="preco"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {message && (
            <div
              className={`w-full p-4 ${messageBg} text-white font-bold text-center`}
            >
              {message}
            </div>
          )}
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="bg-indigo-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-indigo-600"
            >
              Solicitar Certificado
            </button>
          </div>
        </form>
        <Footer />
      </div>
    </>
  );
}
