import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbarCert from "components/Navbars/AdminNavbarCert.js";
import SidebarCert from "components/Sidebar/SidebarCert.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

/* import Dashboard from "views/admin/Dashboard.js"; */
import Dashboardcert from "views/admin/Dashboardcert";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import CertGen from "views/Certificategenerator.js";

export default function CertificateAdmin() {
  return (
    <>
      <SidebarCert />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* <AdminNavbarCert /> */}
        {/* Header */}
       {/*  <HeaderStats /> */}
        <div className="px-4 md:px-10 mx-auto py-16 my-16 w-full -m-24">
          <Switch>
            <Route
              path="/admincert/dashboardcert"
              exact
              component={Dashboardcert}
            />
            {/*  <Route path="/admincert/dashboard" exact component={Dashboard} /> */}
            <Route path="/admincert/maps" exact component={Maps} />
            <Route path="/admincert/settings" exact component={Settings} />
            <Route path="/admincert/tables" exact component={Tables} />
            <Route path="/admincert/genetaror/:id" exact component={CertGen} />
            <Redirect from="/admincert" to="/admincert/dashboardcert" />
          </Switch>
          {/*  <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
