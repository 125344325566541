import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Cert from "../assets/img/Certificado-CPR-VERDE-Frente.png";
/* import { position } from "html2canvas/dist/types/css/property-descriptors/position"; */

const CertGen = ({ match }) => {
  const [certificate, setCertificate] = useState({});
  const certRef = useRef(null);

  useEffect(() => {
    const fetchCertificate = async () => {
      const { data } = await axios.post(
        `https://bcv.institutosudamericanodecarbono.group/api/get/certs/${match.params.id}`
      );
      console.log("data", data);
      console.log(match.params.id);
      setCertificate(data);
    };

    fetchCertificate();
  }, [match.params.id]);

  const handleGeneratePdf = () => {
    html2canvas(certRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("portrait", "mm", [canvas.width, canvas.height]);
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
      pdf.save("certificado.pdf");
    });
  };

  return (
    <>
      <div
        ref={certRef}
        style={{ width: "297mm", height: "425mm", background: "#000" }}
      >
        <div style={{ width: "297mm", height: "210mm", position: "relative" }}>
          <img
            src={Cert}
            alt="background"
            style={{ width: "100%", height: "100%" }}
          />
          <div
            className="pg1"
            style={{
              position: "absolute",
              top: "55%",
              left: "57%",
              width: "80%",
              transform: "translate(-50%, -50%)",
              fontFamily: "Helvetica",
              fontSize: "bold",
            }}
          >
            <p
              className="text-center font-bold"
              style={{ marginTop: "25px" }}
            >
              CERTIFICADO DE VENDA DE CRÉDITOS DE CARBONO VOLUNTÁRIO VIA CPR
              VERDE
            </p>
            <br />

            <div
              style={{
                border: "2px solid green",
                width: "max-content",
                borderRadius: "5px",
                padding: "0 6px",
                fontSize: "17px",
                fontWeight: "bold",
                lineHeight: 1,
              }}
            >
              {`N°: ${certificate.id}`}
              <br />
              <br />
            </div>

            <br />
            <p className=" font-bold">Nome do Comprador:</p>
            <p className="   font-normal">{certificate.nome_comprador}</p>
            <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
            <br />
            <p>Endereço:</p>
            <p className=" font-normal">{certificate.endereco_comprador}</p>

            <hr style={{ border: "#000 dashed 2px", marginTop: "6px" }} />
            <br />
            <p className=" font-bold"> Cidade/Estado:</p>
            <p className="   font-normal">
              {certificate.cidade_estado_comprador}
            </p>
            <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
            <br />
            <p className=" font-bold">
              País:
              <p className="   font-normal relative inline">
                {certificate.pais_comprador}
              </p>
            </p>

            <hr style={{ border: "#000 dashed 2px", marginTop: "6px" }} />
            <br />
            <p className=" font-bold"> Nome do Vendedor:</p>
            <p className="   font-normal">{certificate.nome_vendedor}</p>
            <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
            <br />
            <p className=" font-bold">Endereço:</p>
            <p className="   font-normal">{certificate.endereco_vendedor}</p>
            <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
            <br />
            <p className=" font-bold">
              Cidade/Estado:
              <p className="   font-normal relative inline">
                {certificate.cidade_estado_vendedor}
              </p>
            </p>
            <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
            <br />
            <div className="assign2 flex justify-between">
              <p className=" font-bold">
                País:
                <p className="   font-normal relative inline">
                  {certificate.pais_vendedor}
                </p>
                <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
              </p>

              <p className=" font-bold">
                Data:
                <p
                  className="   font-normal relative inline"
                  style={{ marginRight: "9em" }}
                >
                  {certificate.data_criacao}
                </p>
                <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
              </p>
            </div>
          </div>
        </div>
        <div
          ref={certRef}
          style={{
            width: "297mm",
            height: "210mm",
            marginTop: "10px",
            position: "relative",
          }}
        >
          <img
            src={Cert}
            alt="background"
            style={{ width: "100%", height: "100%" }}
          />
          <div
            style={{
              position: "absolute",
              top: "55%",
              left: "57%",
              width: "80%",
              transform: "translate(-50%, -50%)",
              fontFamily: "Helvetica",
              fontSize: "bold",
            }}
          >
            <div className="txtpg2">
              <p className=" font-bold">
                1. Objeto: O objeto deste Certificado é a venda de créditos de
                carbono voluntário gerados por iniciativa do vendedor, por meio
                da implementação de práticas de redução de emissões de gases de
                efeito estufa em sua propriedade rural.
              </p>
              <br />
              <p className=" font-bold">
                2. Quantidade de créditos de carbono vendidos:
                {certificate.quantidade} toneladas de CO2 equivalente (tCO2e).
              </p>
              <br />
              <p className=" font-bold">
                3. Preço: O preço total da venda dos créditos de carbono é de R$
                {certificate.quantidade} (ou equivalente em outra moeda).
              </p>
              <br />
              <p className=" font-bold">
                4. CPR Verde: Este Certificado é emitido em conexão com o
                Certificado de Recebíveis do Agronegócio Verde (CPR Verde) n°
                {certificate.id}, emitido pelo vendedor.
              </p>
              <br />
              <p className=" font-bold">
                5. Prazo de Validade: O presente Certificado é válido pelo prazo
                de 1 ano, contado a partir da data de sua emissão.
              </p>
              <br />
              <p className=" font-bold">
                6. Jurisdição: Este Certificado é regido e interpretado de
                acordo com a legislação do país em que foi emitido.
              </p>
              <br />
              <p className=" font-bold">
                7. Confirmação do Vendedor: O vendedor confirma que os créditos
                de carbono vendidos foram gerados por meio da implementação de
                práticas de redução de emissões de gases de efeito estufa em sua
                propriedade rural e que esses créditos não foram vendidos ou
                transferidos a terceiros anteriormente.
              </p>
            </div>
            <div className="assign2 flex justify-between mt-20">
              <span className=" font-bold mx-auto">
                <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
                Assinatura do Comprador
              </span>
              <span className=" font-bold mx-auto">
                <hr style={{ border: "#000 dashed 2px", marginTop: "5px" }} />
                Assinatura do Vendedor
              </span>
            </div>
            {/* <h1>{certificate.name}</h1>
            <p>{certificate.description}</p>
            <p>{certificate.date}</p> */}
          </div>
        </div>
      </div>
      <button
        className="mt-10 bg-green-500 p-2 rounded text-white font-bold  border-4 mx-auto"
        onClick={handleGeneratePdf}
      >
        Gerar Certificado
      </button>
    </>
  );
};

export default CertGen;
